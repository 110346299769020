import { createSelector } from "reselect";
import _ from "lodash";

const paymentMethodDataSelector = state => _.mapKeys(state.paymentMethodData.data.paymentmethods, "id");
const selectedPaymentMethodSelector = state => state.selectedPaymentMethod.id

const getSelectedPaymentMethod = (paymentMethodData, selectedPaymentMethodSelector) => {
    
    return paymentMethodData[selectedPaymentMethodSelector]
};

export default createSelector(
    paymentMethodDataSelector,
    selectedPaymentMethodSelector,
    getSelectedPaymentMethod
);


