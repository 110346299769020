import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Icon } from 'semantic-ui-react'
import LazyLoad from "react-lazyload";
import {
  fetchCountry
  // createFuseSearchInstance
} from "../actions";
import CountryDetailSelector from "../selectors/CountryDetailSelector";
import showDefaultLogo from "./showDefaultLogo";

class CountryDetails extends React.Component {
  componentDidMount() {
    this.props.fetchCountry({
      id: this.props.match.params.id,
      name: null
    });
  }

  componentDidUpdate() {
    this.props.fetchCountry({
      id: this.props.match.params.id,
      name: this.props.countryDetails ? this.props.countryDetails.country : null
    });
  }

  printPaymentType = throughpaymentType => {
    return `through ${throughpaymentType}`
  };

  render() {
    const selectedCountry = this.props.countryDetails;

    if (!this.props.countryDetails) {
      return <div>Loading</div>;
    }

    return (
      <div className="payment-details-pane">
        <Link className='back-arrow' to="/">
          <Icon name='arrow left' />
        </Link>
        <div className="details-page-brand">
          <img
            className="country-flag-img"
            onError={showDefaultLogo}
            alt="flag"
            src={`/assets/flags/${this.props.countryDetails.iso}.svg`}
          />
          <h2>{selectedCountry.country}</h2>
        </div>
    <h4>{selectedCountry.country === "Global" ? "Payment methods available to shoppers in countries across the globe" : `Payment methods available to shoppers in ${selectedCountry.country}`}</h4>
    <p>{selectedCountry.country === "Africa" || selectedCountry.country === "Europe" || selectedCountry.country === "Asia" || selectedCountry.country === "North America" || selectedCountry.country === "South America" || selectedCountry.country === "Oceania" ? `Availablity depends on each specific country in ${selectedCountry.country}. Click a payment method to see the countries in which it is available.` : ``}</p>   
        <div className="country-details-pm-list">
          {selectedCountry.paymentmethods.map((paymentMethod, index) => {
            return (
              <div className="country-details-pm" key={index}>
                <Link to={`/paymentmethod/${paymentMethod.id}`} key={index}>
                  <LazyLoad>
                    <img
                      onError={showDefaultLogo}
                      alt="logo"
                      src={`/assets/logos/${paymentMethod.id}.png`}
                    />
                  </LazyLoad>
                  <span className="country-payment-method-name" key={index}>
                    {paymentMethod.name}
                    <span className="payment-method-group"> {paymentMethod.paymentMethodType}</span>
                  </span>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log(ownProps)
  return {
    countryDetails: CountryDetailSelector(state)
  };
};

export default connect(mapStateToProps, {
  fetchCountry
  // createFuseSearchInstance
})(CountryDetails);
