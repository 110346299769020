export default (state = '', action) => {
    switch (action.type) {
      case "TERM_PROVIDED":
        return action.payload;
      case 'FETCH_PAYMENTMETHOD':
        if (action.payload.name) {
          return action.payload.name
        }
        return state
      case 'FETCH_COUNTRY':
          if (action.payload.name) {
            return action.payload.name
          }
          return state  
      default:
        return state; 
    }
  };