import React from "react";
import { connect } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { Container, Item, Grid } from "semantic-ui-react";
import { addBackToTop } from 'vanilla-back-to-top'
import CatalogHeader from "./Header";
import CatalogFooter from "./Footer";
import PaymentMethodList from "./PaymentMethodList";
import PaymentMethodDetails from "././PaymentMethodDetails";
import CountryDetails from "././CountryDetails";
import history from "../history";
import {
  fetchPaymentData
} from "../actions";
import "./css/styles.css";

const App = (props) => {
  props.fetchPaymentData();
  addBackToTop({
    diameter: 56,
    backgroundColor: 'hsl(105, 55%, 36%)',
    textColor: '#fff'
  })

  return (
    <Container>
      <Router history={history}>
        <Grid.Column>
          <CatalogHeader />
        </Grid.Column>

        <Item.Group>
          <Item>
            <Switch>
              <Route path="/" exact component={PaymentMethodList} />
              <Route
                path="/paymentmethod/:id"
                exact
                component={PaymentMethodDetails}
              />
              <Route
                path="/country/:id"
                exact
                component={CountryDetails}
              />
            </Switch>
          </Item>
        </Item.Group>
      </Router>
      <CatalogFooter />
    </Container>
  );
};


const mapStateToProps = state => {
  return {
    paymentMethodData: state.paymentMethodData.data
  };
};

export default connect(mapStateToProps, {
  fetchPaymentData,
})(App);
