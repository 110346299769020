import React from "react";
import { Field, reduxForm } from "redux-form";
import { Popup } from 'semantic-ui-react'

class SelectSearchType extends React.Component {
  renderInput({ input }) {
    return (
      <input
        {...input}
        type="checkbox"
        name="fuzzysearch"
      />
    );
  }
  render() {
    return (
      <form className="search-form">
        <label>
          <Field
            name="fuzzysearch"
            component={this.renderInput}
          />{" "}
          
          Also show approximate results
          <Popup content="Show results that may not exactly correspond to what you are looking for but may still be relevant." on='hover' trigger={<i aria-hidden="true" className="question circle outline icon"></i>} />
        </label>
      </form>
    );
  }
}

export default reduxForm({
  form: "selectSearchTypeForm"
})(SelectSearchType);
