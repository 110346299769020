import React from "react";
//import PaymentMethodTable from "./PaymentMethodTable";
// import checkAllEqual from "./checkAllEqual";
// import {
//   // NOTES,
//   FEATURES,
//   SUPPORTED_STATUS,
//   ONLY_THROUGH_PLATFORMS
// } from "../strings";

class PaymentMethodFeatures extends React.Component {
  componentDidUpdate(props) {
    this.renderFeatures(this.props.specs);
  }


  renderFeatures = paymentmethoddetails => {
    // Get the features you're interested in
    let threedsfeatures = [];
    threedsfeatures.push(
      paymentmethoddetails.threeDsSupported,
      paymentmethoddetails.storedCredentialsSupported
    );

    let paymentfeatures = [];
    paymentfeatures.push(
      paymentmethoddetails.storedCredentialsSupported,
    )

    return (
      <div>
        {/* <h5></h5> */}
        {/* <PaymentMethodTable
          data={threedsfeatures}
          columns={{
            column1: "name",
            column2: "supported",
            column3: null
          }}
          columnTitles={{
            column1: FEATURES,
            column2: SUPPORTED_STATUS,
            column3: null
          }}
          exceptionNotesText={ONLY_THROUGH_PLATFORMS}
          allEqual={checkAllEqual(
            [
              paymentmethoddetails.threeDsOneExternalSupported,
              paymentmethoddetails.threeDsOneInternalSupported,
              paymentmethoddetails.threeDsTwoInternalSupported,
              paymentmethoddetails.threeDsTwoExternalSupported
            ],
            "supported"
          )}
        ></PaymentMethodTable> */}
        {/* <h5>Payments</h5>
        <PaymentMethodTable
        data={paymentfeatures}
          columns={{
            column1: "name",
            column2: "supported",
            column3: null,
            column4: this.checkFeaturesHasNotes(paymentfeatures, 'limitedToProcessingPlatform') ? [{arrKeyName: "limitedToProcessingPlatform", objKeyOne:"platformName", objKeyTwo: "constraints"}] : null
          }}
          columnTitles={{
            column1: FEATURES,
            column2: SUPPORTED_STATUS,
            column3: null,
            column4: NOTES
          }}
          exceptionNotesText={ONLY_THROUGH_PLATFORMS}
          allEqual={checkAllEqual(
            [
              paymentmethoddetails.recurringSupported,
              paymentmethoddetails.storedCredentialsSupported
            ],
            "supported"
          )}>
        </PaymentMethodTable> */}
      </div>
    );

  };

 checkFeaturesHasNotes (featureList, featureColumnName) {

  let hasNotes;
  featureList.some((featureItem)=> {
   return hasNotes = featureItem[0][featureColumnName].length >=1
  })

  return hasNotes

 }; 

  render() {
    return (
      <div>
        {this.renderFeatures(this.props.specs)}
      </div>
    );
  }
}



export default PaymentMethodFeatures;
