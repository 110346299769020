// Reselect selector
// Takes a list of search terms and picks out the relevant payment methods.
import { createSelector } from "reselect";
import removeAccents from "remove-accents"


// Create select functions to pick off the pieces of state we care about for this calculation
const paymentMethodDataSelector = state => state.paymentMethodData.data;
const providedSearchTermSelector = state => state.providedSearchTerm;
const selectedSearchTypeSelector = state => state.form;
const fuseInstanceSelector = state => state.paymentMethodData.countriesFuseInstance;
const searchSorter = resolvedSearchTerm => (a,b) => {
  const resolvedA =  a.country.toLowerCase();
  const resolvedB =  b.country.toLowerCase();
  if(resolvedA.startsWith(resolvedSearchTerm) ){
    return resolvedB.startsWith(resolvedSearchTerm) ? resolvedA.localeCompare(resolvedB) : -1;
  }
  return resolvedB.startsWith(resolvedSearchTerm) ? 1 : resolvedA.localeCompare(resolvedB)
}

let foundCountries = null;

const getCountries = (
  paymentMethodData,
  searchTerm,
  selectedSearchType,
  fuse
) => {
  if (
    typeof selectedSearchType.selectSearchTypeForm != "undefined" &&
    typeof selectedSearchType.selectSearchTypeForm.values != "undefined" &&
    selectedSearchType.selectSearchTypeForm.values.fuzzysearch === true
  ) {
      var foundFuzzyPaymentMethods = [];

      if (searchTerm) {
        fuse.search(searchTerm).forEach(foundItem => {
          foundFuzzyPaymentMethods.push(foundItem.item);
        });

        return foundFuzzyPaymentMethods.sort(searchSorter(searchTerm))
      }
    
  }

  const resolvedSearchTerm = searchTerm.toLowerCase().replace("-", "");
  foundCountries = Object.values(paymentMethodData.countries).filter(country => {
    if (searchTerm) {
      const countryName = removeAccents(country.country.toLowerCase().replace("-", ""));
      if (countryName.includes(resolvedSearchTerm)) {
        return true;
      }

      // const isInPaymentMethodName = country.paymentmethods.find(
      //   countryObj =>
      //   countryObj.name
      //       .toLowerCase()
      //       .includes(searchTerm.toLowerCase())
      // );
      // if (isInPaymentMethodName) {
      //   return true;
      // }

      return false;
    }
     return foundCountries
  });
  
  return resolvedSearchTerm ? foundCountries.sort(searchSorter(resolvedSearchTerm)) : foundCountries;
};

export default createSelector([ paymentMethodDataSelector, providedSearchTermSelector, selectedSearchTypeSelector, fuseInstanceSelector], getCountries
);
