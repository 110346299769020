import React from "react";
import { connect } from "react-redux";
import { selectSearchTerm, fetchPaymentMethod } from "../actions";
import { Search } from "semantic-ui-react";
// import TagManager from "react-gtm-module";
import { Link } from "react-router-dom";
import history from "../history";
import FilteredPaymentMethodSelector from "../selectors/FilteredPaymentMethodSelector";
import FilteredCountrySelector from "../selectors/FilteredCountrySelector";
import Highlighter from "react-highlight-words";
import showDefaultLogo from "./showDefaultLogo";

class SearchPaymentMethods extends React.Component {

    handleResultSelect = (e, { result }) => {
        if (e.key === "Enter") {
            if (result.alldata.type === "paymentmethod") {
                history.push(`/paymentmethod/${result.alldata.id}`);
            }
            if (result.alldata.type === "country") {
                history.push(`/country/${result.alldata.id}`);
            }
        }
    };


    handleSearchInput = e => {
        this.props.selectSearchTerm(e.target.value);
        if (e.target.value === "") {
            history.push("/");
        }
    };


    handleClear = e => {
        history.push("/");
    };

    resultRenderer = ({ alldata, title, fulldesc, image }) => {
        let url = alldata.type === "paymentmethod" ? "paymentmethod" : "country";
        return ( <
            div >
            <
            Link className = "pm-search-result-link"
            to = { `/${url}/${alldata.id}` } >
            <
            img onError = { showDefaultLogo }
            className = "search-results-logo"
            alt = "logo"
            src = { image }
            /> <
            Highlighter highlightClassName = "search-highlight"
            searchWords = {
                [this.props.providedSearchTerm]
            }
            autoEscape = { true }
            textToHighlight = { title } >
            <
            /Highlighter> < /
            Link > <
            /div>
        );
    };

    renderInputValue(providedSearchTerm, selectedPaymentMethod) {
        let termToShow =
            selectedPaymentMethod.name != null ?
            selectedPaymentMethod.name :
            providedSearchTerm;
        return termToShow;
    }


    setFirstFoundPaymentMethod = (o, index) => {
        this.firstFoundPaymentMethod.id = o.id;
    };

    categoryRenderer = ({ name }) => {
        return <div > { name } < /div>;
    };

    buildResults = (paymentMethodResult, countryResult) => {
        let results = [];
        let countries = {
            name: "Countries or Regions",
            results: null
        };

        let paymentmethods = {
            name: "Payment Methods",
            results: null
        };

        if (countryResult.length > 0) {
            countries.results = countryResult;
            results.push(countries);
        }

        if (paymentMethodResult.length > 0) {
            paymentmethods.results = paymentMethodResult;
            results.push(paymentmethods);
        }
        return results
    }

    render() {
        const { providedSearchTerm } = this.props;
        const paymentMethodResult = this.props.filteredPaymentmethods.map(
            (o, index) => {
                return {
                    alldata: o,
                    childKey: o.paymentMethodName,
                    // title: `${o.paymentMethodName} ${o.paymentTypeGroup}`,
                    title: `${o.paymentMethodName}`,
                    image: `/assets/logos/${o.id}.png`
                };
            }
        );

        const countryResult = this.props.filteredCountries.map((o, index) => {
            return {
                title: o.country,
                alldata: o,
                childKey: o.country,
                image: `/assets/flags/${o.iso}.svg`
            };
        });

        let results = this.buildResults(paymentMethodResult, countryResult)

        return ( <
            Search category selectFirstResult minCharacters = { 3 }
            icon = "search"
            fluid className = { "pm-search-bar" }
            placeholder = { "Try 'Visa', 'Romania' or 'South America'" }
            onSearchChange = { e => this.handleSearchInput(e) }
            onResultSelect = { this.handleResultSelect }
            results = { results }
            resultRenderer = { this.resultRenderer }
            value = { providedSearchTerm }
            />        
        );
    }
}

const mapStateToProps = state => {
    return {
        filteredCountries: FilteredCountrySelector(state),
        filteredPaymentmethods: FilteredPaymentMethodSelector(state),
        providedSearchTerm: state.providedSearchTerm,
        selectedPaymentMethod: state.selectedPaymentMethod,
        selectedCountry: state.selectedCountry,
        totalNumberOfPaymentMethods: state.totalNumberPaymentMethods.total
    };
};

export default connect(mapStateToProps, {
    selectSearchTerm,
    fetchPaymentMethod
})(SearchPaymentMethods);