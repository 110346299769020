import React from "react";
import { Header } from "semantic-ui-react";
import SearchPaymentMethods from "./SearchPaymentMethods";
import SelectSearchType from "./SelectSearchType";

import "./css/styles.css";

const CatalogHeader = () => {
  return (
    <div className="message-block">
      <div className="message-top">
      <a className="payu-logo" href="https://corporate.payu.com/"><img className="payu-logo" src="/payulogo.svg" alt="Logo"/></a>
      </div>

      <Header as="h1">Payment Methods</Header>
      <div className="message-block-text">
      </div>
      <div>
        <div className="search-area">
          <SearchPaymentMethods />
          <SelectSearchType />
        </div>
      </div>
    </div>
  );
};

export default CatalogHeader;
