import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import providedSearchTerm from './providedSearchTermReducer'
// import fuseInstance from './fuseInstanceReducer'
import paymentMethodData from './paymentMethodDataReducer'
import selectedPaymentMethod from './selectedPaymentMethodReducer'
import selectedCountry from './selectedCountryReducer'
import totalNumberPaymentMethods from './totalNumberPaymentMethodsReducer'

export default combineReducers({
    providedSearchTerm,
    // fuseInstance,
    paymentMethodData,
    form: formReducer,
    selectedPaymentMethod,
    selectedCountry,
    totalNumberPaymentMethods
});