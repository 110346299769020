import { createSelector } from "reselect";
import _ from "lodash";

const countryDataSelector = state => _.mapKeys(state.paymentMethodData.data.countries, "id");
const selectedCountrySelector = state => state.selectedCountry.id

const getSelectedCountry = (countryData, selectedCountrySelector) => {
    
    return countryData[selectedCountrySelector]
};

export default createSelector(
    countryDataSelector,
    selectedCountrySelector,
    getSelectedCountry
);


