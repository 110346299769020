
import paymentmethodData from "../apis/jira-data.json";
import fuse from "fuse.js";

export default (state = {data: null, paymentMethodsFuseInstance: null, countriesFuseInstance: null}, action) => {
  switch (action.type) {
    case "FETCH_PAYMENTMETHOD_DATA":
        const paymentMethodList = paymentmethodData.paymentmethods
        const countryList = paymentmethodData.countries
        const platformSearchKeys = []
        paymentMethodList.forEach((paymentMethod)=>{
          if (!Array.isArray(paymentMethod.payUPlatforms)) {
            Object.keys(paymentMethod.payUPlatforms).forEach((paymentPlatformKey)=>{
               platformSearchKeys.push('payUPlatforms.' + paymentPlatformKey + '.platform')
            })
          }       
        })
        var optionsPaymentMethods = {
          shouldSort: true,
          includeScore: true,
          threshold: 0.5,
          location: 0,
          distance: 100,
          maxPatternLength: 32,
          minMatchCharLength: 1,
          keys: [...platformSearchKeys, {name: "paymentMethodName", weight: 1}, "country", "payUPlatforms", "throughPaymentType.paymentType", "throughPaymentType.paymentTypeCountry" ]
        };

        var optionsCountries = {
          shouldSort: true,
          includeScore: true,
          threshold: 0.5,
          location: 0,
          distance: 100,
          maxPatternLength: 32,
          minMatchCharLength: 1,
          keys: [{name: "country", weight: 1}, "country"]
        };

        return {data: paymentmethodData, paymentMethodsFuseInstance: new fuse(paymentMethodList, optionsPaymentMethods), countriesFuseInstance: new fuse(countryList, optionsCountries)};
    default:
      return state;
  }
};
