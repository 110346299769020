import React from "react";
import "./css/styles.css";

const CatalogFooter = () => {
  return (
      <footer>
        <p>©2024 PayU All Rights Reserved</p>
        <p><a href="https://corporate.payu.com/privacy/">Privacy Portal</a></p>
      </footer>
  );
};

export default CatalogFooter;
