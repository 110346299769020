// Reselect selector
// Takes a list of search terms and picks out the relevant payment methods.
import { createSelector } from "reselect";
// import paymentMethodData from "../apis/singlePmObject.json";
import removeAccents from "remove-accents"


// Create select functions to pick off the pieces of state we care about for this calculation
const paymentMethodDataSelector = state => state.paymentMethodData.data;
const providedSearchTermSelector = state => state.providedSearchTerm;
const selectedSearchTypeSelector = state => state.form;
const fuseInstanceSelector = state => state.paymentMethodData.paymentMethodsFuseInstance;
// const selectedPaymentMethod = state => state.paymentMethodDetails.selectedPaymentMethod;
const searchSorter = resolvedSearchTerm => (a,b) => {
  const resolvedA =  a.paymentMethodName.toLowerCase();
  const resolvedB =  b.paymentMethodName.toLowerCase();
  if(resolvedA.startsWith(resolvedSearchTerm) ){
    return resolvedB.startsWith(resolvedSearchTerm) ? resolvedA.localeCompare(resolvedB) : -1;
  }
  return resolvedB.startsWith(resolvedSearchTerm) ? 1 : resolvedA.localeCompare(resolvedB)
}

const getPaymentMethods = (
  paymentMethodData,
  searchTerm,
  selectedSearchType,
  fuse
) => {
  if (
    typeof selectedSearchType.selectSearchTypeForm != "undefined" &&
    typeof selectedSearchType.selectSearchTypeForm.values != "undefined" &&
    selectedSearchType.selectSearchTypeForm.values.fuzzysearch === true
  ) {
      var foundFuzzyPaymentMethods = [];

      if (searchTerm) {
        fuse.search(searchTerm).forEach(foundItem => {
          foundFuzzyPaymentMethods.push(foundItem.item);
        });
        return foundFuzzyPaymentMethods.sort(searchSorter(searchTerm))
      }
    
  }

  const resolvedSearchTerm = searchTerm.toLowerCase().replace("-", "");
  const foundPaymentMethods = Object.values(paymentMethodData.paymentmethods).filter(paymentMethod => {
    if (searchTerm) {
      const paymentMethodName = removeAccents(paymentMethod.paymentMethodName.toLowerCase().replace("-", ""));
      if (paymentMethodName.includes(resolvedSearchTerm)) {
        return true;
      }

      if (Array.isArray(paymentMethod.payUPlatforms)) {
        const isPlatformInPaymentMethod = paymentMethod.payUPlatforms.find(
          platform => platform.toLowerCase().includes(searchTerm.toLowerCase())
        );
        if (isPlatformInPaymentMethod) {
          return true;
        }
      }

      if (!Array.isArray(paymentMethod.payUPlatforms)) {
        const groupedPaymentMethodPlatform = Object.keys(
          paymentMethod.payUPlatforms
        );
        const isPlatformInGroupedPaymentMethods = groupedPaymentMethodPlatform.find(
          platform => platform.toLowerCase().includes(searchTerm.toLowerCase())
        );

        if (isPlatformInGroupedPaymentMethods) {
          return true;
        }
      }

      return false;
    }
    return paymentMethodData;
  });
  return resolvedSearchTerm ? foundPaymentMethods.sort(searchSorter(resolvedSearchTerm)) : foundPaymentMethods;
};

export default createSelector([ paymentMethodDataSelector, providedSearchTermSelector, selectedSearchTypeSelector, fuseInstanceSelector], getPaymentMethods
);
