import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import ReactGA from 'react-ga';
import App from "./components/App";
import reducers from "./reducers";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
ReactGA.initialize('UA-113690351-5');
ReactGA.pageview(window.location.pathname);
ReactDOM.render(
  <Provider
    store={createStore(reducers, composeEnhancer(applyMiddleware(reduxThunk)))}
  >
    <App />
  </Provider>,
  document.querySelector("#root")
);
