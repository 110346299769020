import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Icon } from 'semantic-ui-react'
import ReactHtmlParser from 'react-html-parser';
import "./css/styles.css";
import {
  fetchPaymentMethod,
  setPaymentMethodName
  // createFuseSearchInstance
} from "../actions";
import PaymentMethodFeatures from "../components/tabdetailcomponents/PaymentMethodFeatures";
import PaymentMethodDetailSelector from "../selectors/PaymentMethodDetailSelector";
// import PaymentMethodDetailsTabs from "./PaymentMethodDetailsTabs";
import showDefaultLogo from "./showDefaultLogo";

class PaymentMethodDetails extends React.Component {
  componentDidMount() {
    this.props.fetchPaymentMethod({
      id: this.props.match.params.id,
      name: null
    });
  }

  componentDidUpdate() {
    this.props.fetchPaymentMethod({
      id: this.props.match.params.id,
      name: this.props.paymentMethodDetails
        ? this.props.paymentMethodDetails.paymentMethodName
        : null
    });
  }

  // hasThroughPaymentType = throughpaymentType => {
  //   return throughpaymentType.length >= 1;
  // };

  // printPaymentTypes = throughpaymentTypes => {
  //   return throughpaymentTypes.map(paymentTypeObject => {
  //     if (throughpaymentTypes.length > 1) {
  //       return `Available through ${paymentTypeObject.paymentType} in ${paymentTypeObject.paymentTypeCountry}. `;
  //     }
  //     return `Available through ${paymentTypeObject.paymentType}`;
  //   });
  // };

  render() {
    const selectedPaymentMethod = this.props.paymentMethodDetails;

    if (!this.props.paymentMethodDetails) {
      return <div>Loading</div>;
    }

    return (
      <div className="payment-details-pane">
        <Link className='back-arrow' to="/">
          <Icon name='arrow left' />
        </Link>
        <div className="details-page-brand">
          <img
            onError={showDefaultLogo}
            alt="logo"
            src={`/assets/logos/${this.props.paymentMethodDetails.id}.png`}
          />
          <h2>{selectedPaymentMethod.paymentMethodName}</h2>
        </div>
        {/* {this.hasThroughPaymentType(
          selectedPaymentMethod.throughPaymentType
        ) ? (
          <h4>
            {this.printPaymentTypes(selectedPaymentMethod.throughPaymentType)}
          </h4>
        ) : (
          ""
        )} */}
        <p>{ReactHtmlParser(selectedPaymentMethod.paymentMethodDescription)}</p>
        <p>{selectedPaymentMethod.payUPartnerOnly === "Yes" ? `${selectedPaymentMethod.paymentMethodName} is available through a PayU Partner.`: ''}</p>
        {selectedPaymentMethod.pmEncyclopediaLink.length > 0 ? <p><a href={selectedPaymentMethod.pmEncyclopediaLink} target="_blank" rel="noopener noreferrer">Read More</a></p> : null}
        {/* <PaymentMethodDetailsTabs paymentMethod={selectedPaymentMethod} /> */}
        <h5>Available in:</h5>
        {
          <p className="country-list">
            {
              // this.props.paymentMethodDetails.region === "Local" ? 
              this.props.paymentMethodDetails.countries.map((countriesObject, index) => {
              return(
                <Link to={`/country/${countriesObject.id}`} key={index}>
                <span className="country-list-item" key={index}>{countriesObject.countryName}</span>
                </Link>
              )})
            // }) : 
            //   <Link to={`/country/${this.props.paymentMethodDetails.region.toLowerCase()}`} >
            //   <span className="country-list-item">{this.props.paymentMethodDetails.region}</span>
            //   </Link>
             
            }
          </p>
        }
        <PaymentMethodFeatures specs={selectedPaymentMethod} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    paymentMethodDetails: PaymentMethodDetailSelector(state),
    fuseInstance: state.fuseInstance
  };
};

export default connect(mapStateToProps, {
  fetchPaymentMethod,
  setPaymentMethodName
  // createFuseSearchInstance
})(PaymentMethodDetails);
