
export const fetchPaymentData = (paymentmethoddata) => {
  return {
    type: "FETCH_PAYMENTMETHOD_DATA"
  }
};


export const fetchPaymentMethod = ({id, name}) => {
  return {
    type: "FETCH_PAYMENTMETHOD",
    payload: {
      id,
      name
    }
  };
};

export const fetchCountry = ({id, name}) => {
  return {
    type: "FETCH_COUNTRY",
    payload: {
      id,
      name
    }
  };
};

export const setPaymentMethodName = (name) => {
  return {
    type: "SET_PAYMENTMETHOD_NAME",
    payload: name
  };
};

export const setTotalNumberPaymentMethods = (total) => {
  return {
    type: "SET_TOTAL_PAYMENT_METHODS",
    payload: total
  };
};

export const selectSearchTerm = searchTerm => {
  return {
    type: "TERM_PROVIDED",
    payload: searchTerm
  };
};

// export const createFuseSearchInstance = paymentMethodList => {
//   return {
//     type: "FUSE_SEARCH_CREATED",
//     payload: paymentMethodList
//   };
// };


